import React, { createContext, Dispatch, ReactNode, useReducer } from 'react'
import { authReducer } from './reducer'
import { AuthState, Action, User } from './types'

type AuthDispatch = Dispatch<Action>
export const AuthStateContext = createContext<AuthState | null>(null)
export const AuthDispatchContext = createContext<AuthDispatch | null>(null)

export const initialUser: User = {
  name: '',
  picture: '',
  email: '',
  locale: '',
}

export const initialState: AuthState = {
  isAuth: false,
  user: initialUser,
}

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const [auth, dispatch] = useReducer(authReducer, initialState)

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={auth}>{children}</AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  )
}
