import React from 'react'

function EmptyList() {
  return (
    <div className='flex justify-center'>
      <div className='mt-4 text-2xl text-gray-300'>빈 리스트입니다.</div>
    </div>
  )
}

export default EmptyList
