import { useContext } from 'react'
import { AuthStateContext, AuthDispatchContext } from './context'
import { AUTH_SET_USER, User } from './types'

function useAuthState() {
  const state = useContext(AuthStateContext)
  if (state == null) throw new Error('AuthProvider not found')
  return state
}

function useAuthDispatch() {
  const dispatch = useContext(AuthDispatchContext)
  if (dispatch == null) throw new Error('AuthProvider not found')
  return dispatch
}

export function useAuth() {
  const { isAuth, user } = useAuthState()
  const dispatch = useAuthDispatch()

  const handleSetUser = (user: User) => {
    dispatch({
      type: AUTH_SET_USER,
      user,
    })
  }

  return {
    isAuth,
    user,
    handleSetUser,
  }
}
