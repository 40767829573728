export type AuthState = {
  isAuth: boolean
  user: User
}

export type User = {
  name: string
  email: string
  locale: string
  picture: string
}

export type LoginForm = {
  email: string
  password: string
}

export const AUTH_SET_USER = 'AUTH/SET_USER' as const

export type Action = { type: typeof AUTH_SET_USER; user: User }
